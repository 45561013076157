import React from 'react';

import Layout from '../components/Layout';
import {Helmet} from "react-helmet";

export const ContactPageTemplate = () => (
  <main role="main" className="contact-page">
    <Helmet>
      <title>Contact</title>
    </Helmet>
    {/* Begin Treatment and care */}
    <div className="container-fluid bg-gray pt-5 pb-4 contact-page">
      <div className="container pt-5">
        <div className="mx-auto row mb-3">
          <div className="col-md-5 contact-text">
            <h2 className="mb-3">Contact us </h2>
            <p>
              <span className="cont-icon mr-4"><img src="/assets/img/phone-icon.png" alt="" />888-859-0145</span>
              <span className="cont-icon"><img src="/assets/img/contact-mail.png" alt="" />patientinfo@petehealth.com </span>
            </p>
            <p>
          <span className="cont-icon"><img src="/assets/img/contact-wifi.png" alt="" />Address:
            1600 Main St  Venice CA 90291
          </span>
            </p>
            <p className="desk-form-sub">
              {/* <link rel="shortcut icon" type="/asset/img/fav-icon.png" href="/asset/img/fav-icon.png"/> */}
              <img className="img-fluid" src="/assets/img/ph_office_map.png" alt="" />
              {/* <img class="img-fluid" src="/asset/img/map.png" alt=""> */}
            </p>
          </div>
          <div className="col-md-7">
            <div className="contact-form ca-shadow-sm rounded p-4 bg-white m-auto">
              <form className="container">
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <input className="form-control fa-input" id="name" placeholder="Full Name*" type="text" required />
                  </div>
                  {/* form-group */}
                  <div className="form-group col-md-12 col-sm-12">
                    <input aria-describedby="emailHelp" className="form-control fa-input" id="email" placeholder="Email*" type="email" required />
                  </div>
                  {/* form-group */}
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <input className="form-control fa-input" id="phone" placeholder="Phone" type="text" />
                  </div>
                  {/* form-group */}
                  <div className="form-group col-md-12 col-sm-12">
                    <input className="form-control fa-input" id="zip" placeholder="Zipcode" type="text" required />
                  </div>
                  {/* form-group */}
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <textarea className="form-control fa-input" id="reasonforvisit" rows={4} placeholder="Message" defaultValue={""} />
                  </div>
                  {/* form-group */}
                </div>
                <button className="btn ca-btn btn-gr p-3 w-100" type="submit">Send</button>
              </form>
            </div>
          </div>
          <p className="mob-form-sub mt-5 px-3">
            <img className="img-fluid" src="/assets/img/ph_office_map.png" alt="" />
          </p>
        </div>
        <br />
      </div>
    </div>
    {/* End Treatement and care */}

  </main>
);


const ContactPage = () => {
  return (
    <Layout>
      <ContactPageTemplate/>
    </Layout>
  )
};

export default ContactPage;
